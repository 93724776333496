import React from "react";
//import Link from "gatsby-link";
import PageHeader from "../components/Common/PageHeader";
import "./aboutus.less";
//import Img from "gatsby-image";
//import { just_excerpt } from "../utils/textutil";
import Layout from "../components/layout"


const items = [
    {
        date: "2015.6",

        event: "公司成立,獲500萬天使輪投資,專注於智能服務機器人的研發設計-"
    },
    {
      date: "2017.8",
      event: "天機1號服務機器人在澳門科學館正式發布"
    },
    {
      date: "2017.11",
      event:
        "8台天機1號服務機器人參加2017年度 SR Show上海國際服務機器人展,並榮獲最佳商用服務機器人獎"
    },
    {
      date: "2018.1",
      event: "成功研發出養老陪護機器人【小馬哥1號】"
    },
    {
      date: "2018.2",
      event:
        "與中山火炬職業技術學院合辦【天機機器人學院】,設立會展機器人丶機器人自動製造與維修丶機器人導航與遙感三個新專業"
    },
    {
      date: "2018.4.11",
      event:
        "中小型服務機械人「小馬哥1號」於4月11日正式發佈，同時，旅遊局與安信通科技（澳門）有限公司共同發起的“智慧旅遊機械人項目”"
    },
    {
      date: "2018.8",
      event:
        "北京舉行的 “二○一八世界機器人大會”上，安信痛進入國內一線服務機器人廠家展區，德國一代理商即塲預訂兩台小馬哥1號用於德國護理服務機構。"
    },
    {
      date: "2019.1",
      event:
        "安信通位於中山 的粵港澳大灣區機器人基地動工，期望2020年初投入使用成為創新智能制造與研發的示範基地。"
    },
    {
      date: "2019.3",
      event:
        "桌面居家安老機器人測試版正式面世。"
    }
  ];


const AboutusPage = ({ data }) => (
  <Layout>
  <div id="main-wrap">
    {" "}
    {/* Main Wrap  */}
    {/* Page Content  */}
    <div id="page-content" className="header-static">
      {/* Page Header  */}
      <PageHeader title="關於我們">
        <span />
      </PageHeader>
      {/* END Page Header  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        <div className="container">
          <div className="col-md-2" />
          <div className="col-md-8 padding-leftright-null">
            <div className=" padding-onlytop-lg padding-md-top padding-md-bottom-lg">
              {/* <h2 className="text-center margin-bottom-small">關於我們</h2> */}
              <p>
              安信通科技(澳門)有限公司(簡稱Singou)是澳門科技大學科研團隊的研究成果進行產業化而成立的商業實體，核心技術包括身份識別安全算法、車規級低功耗前端智能算法、異構多機器人協同算法以及服務機器人整機研發與製造技術。<br />
<br />
2017年8月「天機1號」大型機器人正式推出市場,具有人臉識別、語音對話、視頻監控、室內室外機動行走等功能,適用於會展服務、考勤簽到、酒店服務、物業安防等場景。 2018年4月「小馬哥1號」中型機器人發佈，主要用於家居、養老、教育、娛樂等應用場景，並於將於今年中推出桌面版居家安老機器人。與此同時，正與國內多家車企合作研發前裝車載機器人。<br />
<br />
為加快產品量產,公司正在中山投資建設一個機器人透明工廠及產學研基地,打造一個面向服務機器人行業的高科技創新智能製造與硏發的示範基地。除機器人研發及製造，公司也為客人提供人工智能算法研究及應用，其港澳車牌識別項目已在港澳多個大型停車場投入應用。<br />
<br />
公司在上海、深圳、香港、濟南、珠海、中山、德國法蘭克福、哈根等地設立有「技術支援中心」。


              </p>
              <p className="text-right">
              <img className="guard" alt="guard" src="/assets/img/aboutus-guards.jpg"/>

              </p>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
        <div className="secondary-background">
          <div className="container">
            {/* CTA */}
            <div className="row no-margin">
                <div className="text text-center">
                  <h2 className="small white slogan">
                  安信通只做最好的服務機器人                  </h2>
                </div>
            </div>
            {/* END CTA */}
          </div>
        </div>
        <div className="container">
          {/* Title */}
          <div className="row no-margin padding-lg">
            <div className="col-md-12 padding-leftright-null">
              <div className="text text-center padding-topbottom-null">
                <h2 className="margin-bottom-null left">
安信通年表                </h2>
              </div>
            </div>
          </div>
          {/* END Title */}
        </div>
        <div className="container">
          {/* Timeline */}
          <div className="row no-margin padding-onlybottom-lg">
            <div className="col-md-12 padding-leftright-null">
              <ul className="timeline">
                {items.map( (item)=>{
                    return (<li>
                        <div className="content">
                          <p>
                           {item.event}
                          </p>
                        </div>
                        <div className="timeline-badge" />
                        <div className="title">

                          <h3>{item.date}</h3>
                          {/* <span>{item.date}</span>  */}
                        </div>
                      </li>)
                })}
              </ul>
            </div>
          </div>
          {/* END Timeline */}
        </div>

      </div>
    </div>
    {/* END Page Content */}
    {/* Main Wrap  */}
  </div>
  </Layout>
);

export default AboutusPage;
